import { ADD_USER, RESET_USER_PW, AUTH_USER } from "../action/auth.action";

const initialState = [];

export default function authUser(state = initialState, action) {
    switch (action.type) {
        case ADD_USER:
            return action.payload
        case RESET_USER_PW:
            return action.payload
        case AUTH_USER:
            return action.payload
        default:
            return state;
    }
}