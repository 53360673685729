import { combineReducers } from "redux";
import articleFullReducer from './articleFull.reducer';
import articleFilterReducer from './articleFilter.reducer';
import articleSelectReducer from './articleSelect.reducer';
import cartReducer from "./cart.reducer";
import authUser from './auth.reducer';

export default combineReducers({
    articleFullReducer,
    articleFilterReducer,
    articleSelectReducer,
    cartReducer,
    authUser
})