import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './_Home.scss'
require('dotenv').config();

const Home = () => {

    const [shopState, setShopState] = useState();
    const serveurIp = process.env.REACT_APP_SERV_URL

    // Change le format d'encodage pour un bon affichage
    const decodeStr = (str) => {
        return str.replace(/&#(\d+);/g, function (match, dec) {
            return String.fromCharCode(dec);
        });
    };



    // Recuperation texte "story" Etsy
    const getState = async () => {
        let response;
        try {
            response = await axios.get(serveurIp + '/getVacation')
            response = response.data
            console.log('getVacation-----', response)
            const shopHello = decodeStr(response.results[0].announcement).split(".")
            const shopVacation = decodeStr(response.results[0].vacation_message).split(".")
            if (typeof response !== "undefined") {
                setShopState({
                    shop_vacationState: response.results[0].is_vacation,
                    shop_vacation: shopVacation,
                    shop_hello: shopHello
                });
            };
        } catch (error) {
            console.log('requestData error:', error);
        }
    }

    useEffect(() => {
        const getData = async () => {
            await getState();
        }
        getData();
    }, [])


    return (
        <div className='home'>
            <div className='home-container'>
                <img className='home-img' src='./img/a-propos1.jpg' />
                <div className='home-txt-container'>
                    {(shopState) &&
                        <>
                            {(shopState.shop_vacationState)
                                ?
                                <>
                                    {shopState.shop_vacation.map((pTxt) => (
                                        <p className='home-txt'>{pTxt + "."}</p>
                                    ))}
                                </>
                                :
                                <>
                                    {shopState.shop_hello.map((pTxt) => (
                                        <p className='home-txt'>{pTxt + "."}</p>
                                    ))}
                                </>
                            }
                        </>
                    }

                </div>
            </div>
        </div>
    );
};


export default Home;