import React, { useState, useEffect } from 'react';

import Article from '../../components/forms/Article/Article';
import isEmpty from '../../components/utils/isEmpty/IsEmpty';
import './_Boutique.scss'

// redux dépendances
import { filterArticle } from "../../redux/action/article.action"
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const Boutique = () => {

    const [isSelect, setIsSelect] = useState(false);
    const [articlesList, setArticlesList] = useState([]);

    let fullArticlesTemp = useSelector((articleFull) => articleFull.articleFullReducer);
    let fullArticles = useSelector((articleFilter) => articleFilter.articleFilterReducer);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(filterArticle(fullArticles));
    }, [fullArticles]);

    return (
        <div className='articles-liste'>
            {!isEmpty(fullArticles) &&
                fullArticles.map((article, index) => (
                    <Article article={article} key={index} />
                ))}
        </div>
    );
};

export default Boutique;