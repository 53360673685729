import React from 'react';
import './_Entretien.scss';

const Entretien = () => {
    return (
        <div className='entretien'>
            <div className='entretien-container'>
                <div className='entretien-img-space' />
                <img className='entretien-img' src='./img/a-propos3.jpg' />
                <div className='entretien-txt-container'>
                    <h1 className='entretien-title'>Conseils d’entretien</h1>
                    <p className='entretien-txt'>Voici quelques recommandations pour prendre soin de votre bijou afin qu’il garde son éclat :<br />
                        Enlevez votre bijou avant de faire du sport.<br />
                        Evitez tout contact avec du parfum ou de l’eau.<br />
                        Conservez votre bijou dans un endroit sec.<br />
                        Utilisez un chiffon doux (type peau de chamois) pour le nettoyer.</p>
                </div>
            </div>
        </div>
    );
};

export default Entretien;