import React, { useEffect, useRef, useState } from 'react';
import './_ProductPage.scss'
import axios from 'axios';

// redux dépendances
import { filterArticle, articleSelect } from "../../redux/action/article.action"
import { addCart } from "../../redux/action/articleCart.action"
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import IsEmpty from '../../components/utils/isEmpty/IsEmpty';
import PicsSlider from './picsSlider/PicsSlider'

require('dotenv').config();


const ProductPage = () => {

    //const [article, setArticle] = useState();
    const [heightTextArea, setHeightTextArea] = useState(0);
    const [listPics, setListPics] = useState([]);
    const [selectedPics, setSelectedPics] = useState('');
    const [showBigPics, setShowBigPics] = useState(false);
    const bigImg = useRef(null)
    //const [articleFilter, setArticleFilter] = useState();

    const serveurIp = process.env.REACT_APP_SERV_URL

    let articleFilterReducer = useSelector((articleFilter) => articleFilter.articleFilterReducer);
    let article = useSelector((articleSelect) => articleSelect.articleSelectReducer);
    let fullCart = useSelector((getCart) => getCart.cartReducer);

    // Partie BackGround
    const dispatch = useDispatch(); // redux dépendances

    const fadeAnimation = (pics) => {
        //let elemPics = document.querySelector(".slider-img");
        bigImg.current.attributes.class.value = 'product-img-big active';

        setTimeout(() => {
            bigImg.current.attributes.class.value = 'product-img-big';
            setSelectedPics(pics.medium_pic)
        }, 150);

    };

    const dispatchArticle = (pre_post) => {
        let increment = 1
        if (pre_post === 'pre') increment = -1

        let articlelength = articleFilterReducer.length

        articleFilterReducer.map((item, index) => {
            console.log(index + increment)
            if (item.image.url_medium === selectedPics
                && index + increment < articlelength
                && index + increment >= 0) {
                dispatch(articleSelect(articleFilterReducer[index + increment]));
            }
        })
    }

    // Dealing with Textarea Height
    const calcHeight = (value) => {
        let numberOfLineBreaks = (value.match(/\n/g) || []).length;
        // min-height + lines x line-height + padding + border
        let newHeight = 25 + numberOfLineBreaks * 25 + 16 + 6;
        return newHeight;
    }

    const getArticleData = async (idArticle) => {
        let response;
        try {
            response = await axios.get(serveurIp + '/getDataArt', {
                params: {
                    idArticle: idArticle
                }
            })
            response = response.data
            let listPics = []
            response.results.map((item) => {
                listPics.push({
                    small_pic: item.url_75x75,
                    medium_pic: item.url_570xN,
                    large_pic: item.url_fullxfull
                });
            });
            //console.log(listPics);
            return listPics;
        } catch (error) {
            console.log('requestData error:', error);
        };
    };

    const handleClickBigPic = () => {
        setShowBigPics(!showBigPics)
    };

    const addCard = (article) => {
        dispatch(addCart(fullCart, article))
    }

    useEffect(() => {

        const getData = async (idArticle) => {
            setListPics(await getArticleData(idArticle));
        }
        setHeightTextArea(calcHeight(article.description) + "px");
        //getData(article.id);
        getData(article.id);
        setSelectedPics(article.image.url_medium)

    }, [article])

    return (
        <>
            {IsEmpty(!article) &&
                <>
                    {(showBigPics) &&
                        <>
                            {console.log('listPics', listPics)}
                            <PicsSlider
                                activePicsUrl={selectedPics}
                                listPicsUp={listPics}
                                stateVisible={(value) => setShowBigPics(value)} />
                        </>
                    }
                    {(!showBigPics) &&
                        <div className='product'>
                            <div className='prodtuct-navigation'>
                                <div className='prodtuct-navigation-link btn-nav'>
                                    Accueil/Boutique/...
                                </div>
                                <div className='prodtuct-navigation-prepost'>
                                    <a className='pre-product btn-nav' onClick={() => dispatchArticle('pre')}>
                                        <svg viewBox="0 0 16 16" fill="currentColor" width="16" height="16">
                                            <path fill="currentColor" d="M11.519 13.934c0.214 0.217 0.214 0.566 0 
                                0.783s-0.561 0.217-0.775 0l-6.264-6.326c-0.214-0.217-0.214-0.567 
                                0-0.783l6.264-6.326c0.214-0.217 0.561-0.217 0.775 0s0.214 0.566 0 
                                0.783l-5.712 5.934 5.712 5.934z">
                                            </path>
                                        </svg>
                                        Précedent
                                    </a>
                                    <a className='post-product btn-nav' onClick={() => dispatchArticle('post')}>
                                        Suivant
                                        <svg viewBox="0 0 16 16" fill="currentColor" width="16" height="16">
                                            <path fill="currentColor" d="M4.48 13.934c-0.214 0.217-0.214 0.566 0 
                                0.783s0.561 0.217 0.775 0l6.264-6.326c0.214-0.217 0.214-0.567 
                                0-0.783l-6.264-6.326c-0.214-0.217-0.561-0.217-0.775 0s-0.214 
                                0.566 0 0.783l5.712 5.934-5.712 5.934z">
                                            </path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            <div className='product-container'>
                                <div className='product-description'>
                                    <div className='product-img'>
                                        <h1 className='product-title small-screen'>{article.title}</h1>
                                        <img className='product-img-big' ref={bigImg} src={selectedPics} onClick={() => handleClickBigPic(selectedPics)} />
                                        <div className='product-img-other'>
                                            {listPics.map((pics, index) => (
                                                <img className='product-img-small' key={'small-pics-' + index} src={pics.small_pic} onClick={() => fadeAnimation(pics)} />
                                            ))}
                                        </div>
                                    </div>
                                    <textarea className='product-detail' style={{ height: heightTextArea }} readOnly='readonly'>
                                        {article.description}
                                    </textarea>
                                </div>
                                <div className='product-shopping'>
                                    <h1 className='product-title large-screen'>{article.title}</h1>
                                    <h2 className='product-price'>{article.prix} €</h2>
                                    <button className='product-addcart' onClick={() => addCard(article)}>Ajouter au panier</button>
                                </div>
                            </div>
                        </div>
                    }

                </>
            }
        </>


    );
};

export default ProductPage;