export const ADD_CART = 'ADD_CART';
export const REMOVE_CART = 'REMOVE_CART';
export const GET_CART = 'GET_CART';

export const addCart = (articleCart, article) => {
    return (dispatch) => {
        if (!articleCart) articleCart = [];
        articleCart.push(article);
        window.localStorage.setItem("jsmBijoux", JSON.stringify(articleCart));
        dispatch({ type: ADD_CART, payload: articleCart });
    };
}

export const removeCart = (articleCart, article) => {
    let newCart = []
    articleCart.map((item) => {
        if (item.artId != article.artId) newCart.push(item)
    })

    return (dispatch) => {
        window.localStorage.setItem("jsmBijoux", JSON.stringify(newCart));
        dispatch({ type: REMOVE_CART, payload: newCart });
    };
}

export const getCart = () => {
    return (dispatch) => {
        let stateLocalSorage = window.localStorage.getItem("jsmBijoux") === null
        if (stateLocalSorage === null) {
            dispatch({ type: GET_CART, payload: [] });
        } else {
            dispatch({ type: GET_CART, payload: stateLocalSorage });
        }
    };
}