import React from 'react';
import { NavLink } from 'react-router-dom';
import './_footer.scss'

const Footer = () => {

    const linkFacebook = "https://fr-fr.facebook.com/jamaissanmonbijou/"
    const linkInstagram = "https://www.instagram.com/jamaissansmonbijou/?hl=fr"

    return (
        <div className='footer'>
            <div className="footer-line" />
            <div className='footer-container'>
                <div className='footer-links'>
                    <div className='footer-links-left'>
                        <NavLink exact to='' className='footer-link-btn btn-nav'>Home</NavLink>
                        <NavLink exact to='boutique' className='footer-link-btn btn-nav'>Boutique</NavLink>
                        <NavLink exact to='a-propos' className='footer-link-btn btn-nav'>A propos de moi</NavLink>
                        <NavLink exact to='a-propos' className='footer-link-btn btn-nav'>Contact</NavLink>
                    </div>
                    <div className='footer-links-right'>
                        <NavLink exact to='entretien' className='footer-link-btn btn-nav'>Conseil d'entretien</NavLink>
                        <NavLink exact to='a-propos' className='footer-link-btn btn-nav'>Livraison & retour</NavLink>
                        <NavLink exact to='cgv' className='footer-link-btn btn-nav'>CGV</NavLink>
                    </div>

                </div>
                <div className='footer-icons'>
                    <a href={linkFacebook} target="_blank">
                        <img className='icon fb-icon' alt='lien vers page facebook' src='./icons/icon-facebook.svg' />
                    </a>
                    <a href={linkInstagram} target="_blank">
                        <img className='icon insta-icon' alt='lien vers page instagram' src='./icons/icon-instagram.svg' />
                    </a>
                </div>

            </div>


        </div>
    );
};

export default Footer;