export const ADD_USER = 'ADD_USER';
export const RESET_USER_PW = 'RESET_USER_PW';
export const AUTH_USER = 'AUTH_USER';

export const addUser = (email, password) => {
    return (dispatch) => {
        dispatch({ type: ADD_USER, payload: email });
    };
}

export const resetUserPw = (email, password) => {
    return (dispatch) => {
        dispatch({ type: RESET_USER_PW, payload: email });
    };
}

export const authUser = (email, password) => {
    return (dispatch) => {
        dispatch({ type: AUTH_USER, payload: email });
    };
}