import { GET_FULL_ARTICLES } from "../action/article.action";

const initialState = [];

export default function articleFullReducer(state = initialState, action) {
    switch (action.type) {
        case GET_FULL_ARTICLES:
            return action.payload
        default:
            return state;
    }
}