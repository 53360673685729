import { ADD_CART, REMOVE_CART, GET_CART } from "../action/articleCart.action";

const initialState = [];

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_CART:
            return action.payload
        case REMOVE_CART:
            return action.payload
        case GET_CART:
            return action.payload
        default:
            return state;
    }
}