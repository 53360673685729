import { SELECT_ARTICLE } from "../action/article.action";

const initialState = [];

export default function articleSelectReducer(state = initialState, action) {
    switch (action.type) {
        case SELECT_ARTICLE:
            return action.payload
        default:
            return state;
    }
}