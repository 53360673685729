import axios from 'axios';
require('dotenv').config();

export const FILTER_ARTICLES = 'FILTER_ARTICLES';
export const GET_FULL_ARTICLES = 'GET_FULL_ARTICLES';
export const SELECT_ARTICLE = 'SELECT_ARTICLE';

export const filterArticle = (fullArticles, filtre) => {

    return (dispatch) => {
        const listeArticlesFiltre = () => {
            return fullArticles.filter(article => {
                if (article.title.includes(filtre)) return true;
            });
        };

        const filtreArticle = () => {
            if (filtre) {
                const articles = listeArticlesFiltre()
                dispatch({ type: FILTER_ARTICLES, payload: articles });
            } else {
                dispatch({ type: FILTER_ARTICLES, payload: fullArticles });
            };
        }
        filtreArticle();
    };
}

export const fullArticle = () => {

    const serveurIp = process.env.REACT_APP_SERV_URL

    return (dispatch) => {

        // Change le format d'encodage pour un bon affichage
        const decodeStr = (str) => {
            return str.replace(/&#(\d+);/g, function (match, dec) {
                return String.fromCharCode(dec);
            });
        };

        // Traitement des articles
        const traitementArticles = async (listeArticles) => {
            let articlesTraiter = []
            console.log('listeArticles', listeArticles)
            listeArticles.map((article) => {
                let artTitle = decodeStr(article.title)
                let artId = article.listing_id
                let artType = article.taxonomy_path
                let artMaterial = article.materials
                let artDescrip = decodeStr(article.description)
                let artPrice = article.price
                let artQte = article.quantity
                let artFollow = article.num_favorers
                let artPics = {
                    url_small: article.MainImage.url_170x135,
                    url_medium: article.MainImage.url_570xN,
                    url_large: article.MainImage.url_fullxfull
                };
                articlesTraiter.push({
                    id: artId,
                    title: artTitle,
                    type_article: artType,
                    materiaux: artMaterial,
                    description: artDescrip,
                    prix: artPrice,
                    image: artPics,
                    qte: artQte,
                    like: artFollow
                })
            });
            dispatch({ type: GET_FULL_ARTICLES, payload: articlesTraiter });
            return articlesTraiter
        }

        const fetchData = async () => {
            let response;
            try {
                response = await axios.get(serveurIp + '/getData')
                response = response.data
                response = traitementArticles(response.results)
                console.log('response fetchData', response)
            } catch (error) {
                console.log('requestArticle error:', error);
            }
        }

        fetchData();
    }
};

export const articleSelect = (article) => {

    return (dispatch) => {
        dispatch({ type: SELECT_ARTICLE, payload: article });
    };
}