import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import './_navbar.scss';

// redux dépendances
import { filterArticle } from "../../redux/action/article.action"
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const NavBar = ({ initialState, handleChangeState }) => {

    const [initialNav, setInitalNav] = useState(true);
    const [cartFull, setCartFull] = useState([]);

    // Partie BackGround
    const dispatch = useDispatch(); // redux dépendances

    const fullArticles = useSelector((articleFull) => articleFull.articleFullReducer);
    const fullCart = useSelector((getCart) => getCart.cartReducer);

    const classNameElemLine = 'link-btn-line'
    const classNameElemTexte = 'link-btn btn-nav'

    const handleClickLink = (e, filter) => {

        // Ajout ligne sous selection
        let elems = document.querySelectorAll('.link-btn-line');
        Array.from(elems).map(elem => {
            elem.classList.value = classNameElemLine;
            elem.parentElement.classList.value = classNameElemTexte;
        });

        e.target.classList.toggle('active');
        e.target.parentElement.classList.toggle('active');

        // Filtre les articles
        if (typeof filter != 'undefined') {
            dispatch(filterArticle(fullArticles, filter));
        }
    };

    useEffect(() => {
        setInitalNav(initialState)
    }, [initialState]);

    const getCart = () => {
        const localStorageState = window.localStorage.getItem("jsmBijoux");
        setCartFull(JSON.parse(localStorageState));
        const element = document.querySelector('.container-cart')
        if (element) {
            element.classList.toggle('active');
            setTimeout(() => {
                element.classList.toggle('active');
            }, 3000);
        }
    };

    useEffect(() => {
        window.addEventListener('click', getCart);
        getCart();
        return () => window.removeEventListener('click', getCart);
    }, []);

    return (
        <nav className='navbar'>

            <div className='list-menu'>
                <div className='left-menu' />
                {(initialNav)
                    ?
                    <>
                        <div className='site-menu'>
                            <ul id='sitenav' className='sitenav'>
                                <NavLink exact to='boutique' className={classNameElemTexte} onClick={(e) => {
                                    handleClickLink(e, ``);
                                    handleChangeState(!initialNav);
                                    setInitalNav(!initialNav);
                                }}>
                                    Boutique
                                    <li className={classNameElemLine}></li>
                                </NavLink>
                                <NavLink exact to='a-propos' className={classNameElemTexte} onClick={(e) => handleClickLink(e)}>
                                    A propos de moi
                                    <li className={classNameElemLine}></li>
                                </NavLink>
                            </ul>
                        </div>
                        <div className='rigth-menu' />
                    </>
                    : <>
                        <div className='site-menu'>
                            <ul id='sitenav' className='sitenav'>
                                <NavLink exact to='boutique' className={classNameElemTexte} onClick={(e) => handleClickLink(e, `reille`)}>
                                    Boucles d'oreilles
                                    <li className={classNameElemLine}></li>
                                </NavLink>
                                <NavLink exact to='boutique' className={classNameElemTexte} onClick={(e) => handleClickLink(e, `ollier`)}>
                                    Colliers
                                    <li className={classNameElemLine}></li>
                                </NavLink>
                                <NavLink exact to='boutique' className={classNameElemTexte} onClick={(e) => handleClickLink(e, 'racelet')}>
                                    Bracelets
                                    <li className={classNameElemLine}></li>
                                </NavLink>
                                <NavLink exact to='boutique' className={classNameElemTexte} onClick={(e) => handleClickLink(e, 'ague')}>
                                    Bagues
                                    <li className={classNameElemLine}></li>
                                </NavLink>
                                <NavLink exact to='boutique' className={classNameElemTexte} onClick={(e) => handleClickLink(e, '')}>
                                    Tous
                                    <li className={classNameElemLine}></li>
                                </NavLink>
                            </ul>
                        </div>
                        <div className='rigth-menu'>
                            <NavLink exact to='login'>
                                <button className='btn-connect'>
                                    <img className='icon connect-icon' alt='bouton de connection' src='./icons/user_icon.svg' />
                                </button>
                            </NavLink>
                            <button className='btn-cart'>
                                <img className='icon cart-icon' alt='bouton de panier' src='./icons/icon-cart2.svg' />
                                {cartFull &&
                                    <div>
                                        <a className='nb-article'>{cartFull.length}</a>

                                    </div>
                                }
                            </button>
                        </div>
                        <div className='container-cart'>
                            {(cartFull) &&
                                <>
                                    {cartFull.map((item) => (
                                        <div className="item-cart">
                                            <img className='cart-img' src={item.image.url_small} />
                                            <a className='cart-txt'>{item.title.split(',')[0]}</a>
                                            <a className='cart-price'>{item.prix}€</a>
                                        </div>
                                    ))}
                                </>
                            }
                        </div>
                    </>
                }
            </div>



            <div className="navbar-line" />
        </nav>
    );
};

export default NavBar;