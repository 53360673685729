import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Login.scss'

const Login = () => {

    const loginID = {
        email: '',
        password: ''
    };

    const [login, setLogin] = useState(loginID)

    const { email, password } = login

    const handelChange = (e) => {
        setLogin({ ...login, [e.target.id]: e.target.value })
    }

    const handelSubmmit = (e) => {
        e.preventDefault()
        console.log(login)
    }

    return (
        <div className='connection-page'>
            <h1>Connexion</h1>
            <form className='form-connection' onSubmit={handelSubmmit}>
                <label from='email' >E-mail</label>
                <input type='email' onChange={handelChange} id='email' value={email}></input>
                <label from='email'>Mot de passe</label>
                <input type='password' onChange={handelChange} id='password' value={password}></input>
                <div className='btn-form-connection'>
                    <NavLink exact to='missing-password'>
                        <h4 className='missing-pw'>Mot de passe oublié ?</h4>
                    </NavLink>
                    <button className='submit-log' type='submit'>Se Connecter</button>
                    <NavLink exact to='register'>
                        <h4 className='create-log'>Créer un compte</h4>
                    </NavLink>
                </div>
            </form>
        </div>
    );
};

//missing-password

export default Login;