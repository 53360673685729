/**
 * Teste si une valeur est null / undefined / vide / false
 * @param {*} value 
 * @returns 
 */
const IsEmpty = (value) => {
    return (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0) ||
        (typeof value === "boolean" && value === false) ||
        (typeof value === "undefined" && value === false)
    );
};

export default IsEmpty;