import React from 'react';
import './_Cgv.scss'

const Cgv = () => {
    return (
        <div className="contrat-de-vente">
            <h3>Conditions générales de vente</h3>
            <br />
            <p>Boutique « jamais sans mon bijou »</p>
            <br />
            <p>Conditions Générales de ventes au 15 août 2021</p>
            <br />
            <p>La société « jamais sans mon bijou » – est inscrite en tant que micro-entreprise.<br />
                Son adresse de courrier électronique est :<br />
                jamais.sans.mon.bijou@gmail.com<br />
                Numéro SIRET : 84920088600018</p>
            <br />
            <h3>Préambule</h3>
            <p>Les présentes « Conditions générales de vente » ont pour objet de définir les termes et conditions auxquels « jamais sans mon bijou » propose et vend ses produits à ses clients.<br />
                Toute prise de commande au titre d’un produit figurant au sein de la boutique en ligne du site web implique l’acceptation des présentes conditions générales.<br />
                Ces conditions générales de vente prévaudront sur toutes autres conditions figurant dans tout autre document, sauf dérogation préalable, expresse et écrite.<br />
                L’ensemble de ces informations est présenté en langue française. Le consommateur déclare avoir la pleine capacité juridique lui permettant de s’engager au titre des présentes conditions générales.</p>
            <br />
            <h3>Article 1 : Champs d’Application et Modification des Conditions Générales de Vente</h3>
            <p>Les présentes conditions générales de vente s’appliquent à toutes commandes passées sur le site Internet.<br />
                « Jamais sans mon bijou » se réserve la possibilité d’adapter ou de modifier à tout moment les présentes conditions générales de vente. En cas de modification, il sera appliqué à chaque commande les conditions générales de vente en vigueur au jour de la commande.<br />
                Le client reconnaît avoir pris connaissance, au moment de l’enregistrement de sa commande, des conditions particulières de vente énoncées sur cette page et déclare expressément les accepter sans réserve.<br />
                Les présentes conditions générales de vente régissent les relations contractuelles entre « Jamais sans mon bijou » et son client : les deux parties les acceptant sans réserve.</p>
            <br />
            <h3>Article 2 : Terminologie</h3>
            <p>Les produits proposés à la vente par « jamais sans mon bijou » sur sa boutique en ligne seront ci-après dénommés les « produits ». Chaque personne souhaitant bénéficier des produits et qui satisfera aux présentes conditions générales sera ci-après dénommés un « client » ; chaque commande effectuée par un client dans les conditions prévues sera ci-après dénommée « la commande ».</p>
            <br />
            <h3>Article 3 : Les produits</h3>
            <p>Tous les produits proposés sur la boutique en ligne sont disponibles dans leur entrepôt dans la limite des stocks disponibles.<br />
                En cas d’indisponibilité du produit après passation de la commande, le client sera informé par mail sous 48 heures, des articles en rupture afin que le client puisse alors changer son article et passer commande en direct d’un autre modèle, ou alors, suivant les fournisseurs, et le produit choisi.</p>
            <br />
            <h3>Article 4 : Allergies aux produits</h3>
            <p>En cas d’allergies aux produits, La société « jamais sans mon bijou » ne pourra voir sa responsabilité contractuelle engagée.</p>
            <br />
            <h3>Article 5 : Description des produits</h3>
            <p>Les renseignements mentionnés sur chaque fiche-produit ne sont donnés qu’à titre indicatif.<br />
                Les photographies, textes, graphismes, informations et caractéristiques reproduits et illustrant les produits présentés ne sont pas contractuels. Si des erreurs y figurent, la responsabilité de « Jamais sans mon bijou » ne pourra être engagée. L’entreprise “Jamais sans mon bijou” s’engage à faire ses meilleurs efforts pour corriger le plus rapidement possible les erreurs ou omissions après en avoir été informé.<br />
                Les couleurs des articles présentés sur cette boutique en ligne, peuvent être légèrement différentes de leurs couleurs réelles. La précision des photos étant parfois imprécise suivant la qualité de certains de nos appareils utilisés, merci de bien lire alors la description du produit afin que vous preniez connaissance de sa composition et de l’accepter tel qu’il est représenté afin d’éviter tout propos ou désaccord.<br />
                « Jamais sans mon bijou » ne saurait être tenu pour responsable de l’inexécution du contrat conclu en cas de rupture de stock et indisponibilité du produit, de force majeure, de perturbation ou grève totale ou partielle notamment des services postaux et moyens de transports et/ou communications, inondations, incendie.</p>
            <br />
            <h3>Article 6 : Modalités de commande</h3>
            <p>La société « jamais sans mon bijou » présente sur son site web les produits à vendre avec les caractéristiques nécessaires qui permettent de respecter l’article L111–1 du Code de la consommation, qui énonce la possibilité pour le consommateur potentiel de connaître avant la prise de commande définitive les caractéristiques essentielles des produits qu’il souhaite acheter.<br />
                Les offres présentées par « jamais sans mon bijou » ne sont valables que dans la limite des stocks disponibles. « Jamais sans mon bijou » se réserve le droit de modifier l’assortiment des produits selon les contraintes imposées par ses fournisseurs.<br />
                Seules les personnes juridiquement capables de souscrire des contrats concernant les biens et les services proposés sur le site peuvent commander sur le site de « jamais sans mon bijou ».<br />
                Conformément à la loi du 13 mars 2000 sur la signature électronique, tout bon de commande signé du client par « double clic » constitue une acceptation irrévocable qui ne peut être remise en cause que dans les cas limitativement prévus dans les présentes conditions générales de vente comme « droit de rétractation » et « rupture de stock ».<br />
                Le « double clic » associé à la procédure d’authentification et de non répudiation et à la protection de l’intégralité des messages constitue une signature électronique. Cette signature électronique a valeur entre les parties comme une signature manuscrite.</p>
            <br />
            <h3>Article 7 : Confirmation de commande</h3>
            <p>Les données enregistrées par « jamais sans mon bijou » constituent la preuve de l’ensemble des transactions passées entre « jamais sans mon bijou » et ses clients. Les informations contractuelles sont présentées en langue française et feront l’objet d’une confirmation par voie d’email au plus tard au moment de la livraison.<br />
                « Jamais sans mon bijou » se réserve le droit de ne pas confirmer une commande pour quelques raisons que ce soit, tenant notamment soit à un problème d’approvisionnement de produits, soit à un problème concernant la commande reçue.<br />
                Le client se doit de vérifier l’exhaustivité et la conformité des renseignements qu’il fournit à « jamais sans mon bijou ». Cette dernière ne saurait être tenue responsable d’éventuelles erreurs de saisie et des conséquences en termes de retards ou d’erreurs de livraison. Dans ce contexte, tous les frais engagés pour la réexpédition seront à la charge du client.</p>
            <br />
            <h3>Article 8 : Preuves de la transaction</h3>
            <p>Les registres informatisés, conservés dans les systèmes informatiques de « jamais sans mon bijou » dans des conditions raisonnables de sécurité, seront considérés comme les preuves des communications, des commandes et des paiements intervenus entre les parties. L’archivage des bons de commande et des factures est effectué sur un support fiable et durable pouvant être produit à titre de preuve.</p>
            <br />
            <h3>Article 9 : Prix</h3>
            <p>Les prix de nos produits sont indiqués en euros toutes taxes comprises, hors participation aux frais de traitement et d’expédition.<br />
                L’intégralité du paiement doit être réalisé lors de la commande.<br />
                Pour les livraisons au sein de l’Union Européenne, les prix tiennent compte de la TVA applicable au jour de la commande et tout changement du taux légal de TVA sera automatiquement répercuté sur le prix des produits présentés sur le site, à la date stipulée par le décret d’application.<br />
                Pour une livraison hors de l’Union Européenne, le client devra acquitter les droits de douane, TVA ou autres taxes dues à l’occasion de l’importation des produits dans le pays du lieu de livraison.<br />
                Les formalités qui s’y rapportent sont également à la charge exclusive du client sauf indications contraires. Le client est seul responsable de la vérification des possibilités d’importation des produits commandés au regard des droits du territoire du pays de livraison. « Jamais sans mon bijou » se réserve le droit de modifier ses prix à tout moment et de refuser toute commande d’un client avec lequel il existerait un litige.<br />
                « Jamais sans mon bijou » se réserve le droit de modifier ses prix à tout moment, mais vous sauront appliqués sur la base des tarifs en vigueur qui vous auront été indiqués au moment de votre commande, sous réserve des disponibilités à cette date.
                En cas d’erreur dans le libellé des coordonnées du destinataire, la boutique ne saurait être tenu responsable dans le sens où la livraison ne pourra alors pas s’effectuer comme il se doit.</p>
            <br />
            <h3>Article 10 : Disponibilité des produits</h3>
            <p>Les offres contenues dans ce site sont valables tant que les produits qui s’y rapportent demeurent en ligne et jusqu’à épuisement des stocks. La commande sera exécutée au plus tard dans un délai de 14 jours à compter du jour suivant celui où la commande du client a été définitivement validée (moyen de paiement reçu et procédure de vérification de commande effectuée).<br />
                En cas d’indisponibilité du produit commandé, notamment du fait de nos fournisseurs, le client en sera informé au plus tôt et aura la possibilité d’annuler sa commande. Le client aura alors le choix de demander soit le remboursement, le cas échéant, des sommes versées dans les 14 jours au plus tard de leur versement, soit un échange de produit de caractéristiques et de prix équivalent, si « jamais sans mon bijou » est en mesure de se le procurer.<br />
                En cas d’impossibilité d’échange, « jamais sans mon bijou » se réserve le droit d’annuler la commande du client et de rembourser les sommes versées. Les informations sur la disponibilité des produits étant transmises par nos fournisseurs, l’indisponibilité du produit ne saurait engager la responsabilité de « jamais sans mon bijou » ni ouvrir droit à des dommages et intérêts pour le client.</p>
            <br />
            <h3>Article 11 : Modalités de paiement</h3>
            <p>Vous pouvez régler vos achats par carte bancaire ou par compte Paypal.<br />
                « Jamais sans mon bijou » se réserve le droit de refuser ou d’annuler toute commande d’un client notamment en cas d’insolvabilité dudit client ou dans l’hypothèse d’un défaut de paiement de la commande en question ou d’un litige relatif au paiement d’une commande antérieure.<br />
                « Jamais sans mon bijou » se réserve donc le droit de suspendre toute gestion de commande et toute livraison en cas de non autorisation de paiement de la part des organismes officiellement accrédités ou en cas de non paiement.<br />
                « Jamais sans mon bijou » se réserve notamment le droit de refuser d’effectuer une livraison et d’honorer une commande émanant d’un consommateur qui n’aurait pas réglé totalement ou partiellement une commande précédente ou avec lequel un litige serait en cours d’administration.<br />
                « Jamais sans mon bijou » conserve la propriété pleine et entière des produits vendus jusqu’à parfait encaissement de toutes les sommes dues par le client dans le cadre de sa commande, frais et taxes compris.<br />
                Le transfert de propriété d’un bien acheté à « jamais sans mon bijou » par le consommateur à une tierce personne, ne sera pas pris en charge par « jamais sans mon bijou ». Seul l’acheteur d’origine de ce bien peut prétendre accéder aux clauses des présentes conditions générales de vente.</p>
            <br />
            <h3>Article 12 : Livraison</h3>
            <p>La zone géographique de livraison correspond à la zone géographique de couverture de l’offre. Les produits sont livrés à l’adresse indiquée par le consommateur sur le bon de commande. La livraison est réputée effectuée dès la remise du produit au transporteur La Poste. Le client se doit de vérifier l’exhaustivité et la conformité des renseignements qu’il fournit à « jamais sans mon bijou ». Cette dernière ne saurait être tenue responsable d’éventuelles erreurs de saisie et des conséquences en termes de retard ou d’erreur de livraison. Dans ce contexte, tous les frais engagés pour la réexpédition de la commande seront entièrement à la charge du client.<br />
                « jamais sans mon bijou » ne saurait être tenu pour responsable de retards de livraison en raison d’erreurs ou de perturbations imputables aux transporteurs (grèves totale ou partielle notamment des services postaux et moyens de transports et/ou communication).<br />
                Les retards éventuels de livraison imputables au transporteur ou « jamais sans mon bijou » ne donnent en aucun cas le droit au client de réclamer des dommages et intérêts.<br />
                Le consommateur est tenu de vérifier l’état de l’emballage et de la marchandise à la livraison et de signaler les dommages dus au transporteur sur le bon de livraison, ainsi qu’à « jamais sans mon bijou » dans un délai de deux jours ouvrés.<br />
                <br />
                Délais de livraison: les préparations de commande sont en général réalisés entre 3 et 5 jours après validation de la commande (temps de fabication). Toutefois, si la commande présente un nombre important d’articles le délai de préparation sera allongé et le client en sera averti par mail.</p>
            <br />
            <h3>Article 13 : problème de livraison</h3>
            <li>13-a : problème de livraison du fait du transporteur</li>
            <br />
            <p>Toute anomalie concernant la livraison (avarie, produit manquant par rapport au bon de livraison, colis endommagé, produits cassés…) devra être impérativement indiquée sur le bon de livraison sous forme de « réserves manuscrites », accompagnée de la signature du client.<br />
                Dans le cas d’un envoi effectué par la Poste, si le colis arrive ouvert ou endommagé (notamment avec la présence du scotch Jaune « La Poste ») il est indispensable que le client fasse établir par le facteur ou le bureau de Poste dont il dépend un « constat de spoliation » afin que « jamais sans mon bijou » puisse ouvrir une enquête et une procédure d’indemnisation.<br />
                Le client devra parallèlement confirmer cette anomalie en adressant au transporteur dans les (2) jours ouvrables suivants la date de livraison un courrier recommandé avec accusé de réception exposant lesdites réclamations.<br />
                Le client devra transmettre simultanément copie de ce courrier (avec l’original du « constat de spoliation » le cas échéant) par simple courrier à : jamais sans mon bijou. Toute réclamation parvenue hors délai sera rejetée.</p>
            <br />
            <li>13-b : problème de livraison du fait d’une erreur de livraison – produits non conformes</li>
            <br />
            <p>Le client devra formuler auprès de « jamais sans mon bijou » le jour même de la livraison ou au plus tard le premier jour ouvré suivant la livraison, toute réclamation d’erreur de livraison et/ou de non-conformité des produits en nature ou en qualité par rapport aux indications figurant sur le bon de commande. Toute réclamation formulée au-delà de ce délai sera rejetée sans possibilité de recours. La formulation de cette réclamation auprès de « jamais sans mon bijou » pour être faite :<br />
                – par mail à l’adresse suivante : jamais.sans.mon.bijou@gmail.com<br />
                Toute réclamation non effectuée dans les règles définies ci-dessus et dans les délais impartis ne pourra être prise en compte et dégagera « jamais sans mon bijou » de toute responsabilité vis-à-vis du consommateur.<br />
                Tout produit à échanger ou à rembourser devra être retourné dans les 14 jours ouvrés suivant la réception de l’accord de retour de « jamais sans mon bijou » dans son intégralité (accessoires et notices compris) et dans son emballage d’origine en parfait état. Pour être accepté, tout retour devra être signalé au préalable à « jamais sans mon bijou ». Les frais d’envois avancés par le client ne seront pas remboursés par « jamais sans mon bijou », sauf dans le cas où ce retour serait constitutif à une erreur de « jamais sans mon bijou ». Faute de respect de la procédure ci-dessus aucune réclamation pour non-conformité ou vice apparent des produits livrés ne pourra être acceptée. Les frais de retour sont à la charge du client.</p>
            <br />
            <h3>Article 14 : Droit de rétractation</h3>
            <p>Le client dispose d’un délai de 14 jours à compter de la réception des produits commandés pour les retourner à la boutique « jamais sans mon bijou » contre échange ou remboursement (celui ci sera effectué dans un délai maximum de 14 jours dans le même mode de paiement, hors frais de livraison).<br />
                Afin que l’article soit échangé, le client devra alors renvoyer les articles neufs dans leur emballage d’origine, intacts, non portés, et à ses frais, à Jamais sans mon bijou<br />
                Les retours s’effectuent sous la responsabilité du client. « jamais sans mon bijou » ne pourra pas rembourser ou échanger tout article qu’il n’aura pas reçu en cas de retour (perte ou vol du colis par La Poste par exemple).</p>
            <br />
            <p>Attention, les produits ayant fait l’objet d’une personnalisation ne seront ni repris ni échangés !</p>
            <br />
            <h3>Article 15 : Propriété intellectuelle</h3>
            <p>« jamais sans mon bijou » est titulaire des droits de propriété intellectuelle sur le site et du droit de diffuser les éléments figurant sur le catalogue de la boutique en ligne et notamment les photographies pour lesquels elle a obtenu les autorisations nécessaires des personnes concernées.<br />
                À ce titre et conformément aux dispositions du Code de la Propriété Intellectuelle, la reproduction partielle ou totale, sur quelque support que ce soit, des éléments composant le site et le catalogue produits, leur utilisation ainsi que leur mise à disposition de tiers sont formellement interdits.</p>
            <br />
            <h3>Article 16 : Responsabilité – Litiges – Droit applicable</h3>
            <p>Les photographies et les textes reproduits et illustrant les produits présentés ne sont pas contractuels. En conséquence, la responsabilité de « jamais sans mon bijou » ne saurait être engagée en cas d’erreur dans l’une de ces photographies ou l’un de ces textes.<br />
                « jamais sans mon bijou » ne pourra être tenu responsable des dommages de toute nature, tant matériels qu’immatériels ou corporels, qui pourraient résulter d’un mauvais fonctionnement ou de la mauvaise utilisation des produits commercialisés. Il en est de même pour les éventuelles modifications des produits résultant des fournisseurs.<br />
                La responsabilité de « jamais sans mon bijou » sera, en tout état de cause, limitée au montant de la commande et ne saurait être mise en cause pour de simples erreurs ou omissions qui auraient pu subsister malgré toutes les précautions prises dans la présentation des produits.<br />
                « jamais sans mon bijou » ne pourra être tenu responsable, envers un client ou un tiers, d’aucun dommage indirect, d’aucune perte d’exploitation, de profit ou de chiffre d’affaires, survenus de quelque manière que ce soit, même si ce dommage ou cette perte ou ce préjudice était prévisible par « jamais sans mon bijou », ou si son éventualité avait été portée à son attention.<br />
                « jamais sans mon bijou » ne saurait être tenu pour responsable de l’inexécution du contrat conclu en cas de rupture de stock ou indisponibilité du produit, de force majeure, de perturbation ou grève totale ou partielle notamment des services postaux et moyens de transport et/ou communications, inondation, incendie, tremblement de terre, inondations, foudre.<br />
                En cas de litige, le membre s’adressera par priorité à « jamais sans mon bijou » pour obtenir une solution amiable.<br />
                Les présentes conditions générales de vente en langue française seront exécutées et interprétées conformément au droit français. Tout différend qui pourrait naître à l’occasion de sa validité, de son interprétation ou de son exécution sera soumis aux Tribunaux compétents.</p>
            <br />
            <h3>Article 17 : Informations nominatives</h3>
            <p>« jamais sans mon bijou » s’engage à ne pas divulguer à des tiers les informations communiquées par les clients sur le site. Celles-ci sont confidentielles. Elles ne seront utilisées par ses services internes que pour le traitement de votre commande et que pour renforcer et personnaliser la communication notamment par les lettres / emails d’information ainsi que dans le cadre de la personnalisation du site en fonction des préférences constatées des utilisateurs.<br />
                Conformément à la loi informatique et libertés du 6 janvier 1978, vous disposez d’un droit d’accès, de rectification, de modification et de suppression des données vous concernant.<br />
                Vous pouvez exercer ce droit en nous envoyant un courrier.</p>
            <br />
            <h3>Article 18 : Modification des conditions générales de vente</h3>
            <p>« jamais sans mon bijou » se réserve le droit de modifier les conditions générales de vente de produits sur la boutique en ligne.<br />
                Dans l’hypothèse où l’un quelconque des termes des conditions générales de vente serait considéré comme illégal ou inopposable par une décision de justice les autres dispositions resteront en vigueur.</p>
            <br />
        </div>
    );
};

export default Cgv;