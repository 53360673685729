import React, { useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import NavBar from './components/navBar/NavBar';
import Footer from './components/footer/Footer';
import APropos from './pages/a_propos/APropos';
import Home from './pages/home/Home';
import { NavLink } from 'react-router-dom';
import Boutique from './pages/boutique/Boutique';
import ProductPage from './pages/product_page/ProductPage';
import Cgv from './pages/CGV/Cgv';
import Entretien from './pages/entretien/Entretien';
import LogoSite from './components/logoSite/LogoSite';
import Login from './pages/login/Login';
import LoginMissing from './pages/loginMissing/LoginMissing';
import LoginCreate from './pages/loginCreate/LoginCreate';
require('dotenv').config();

function App() {

  const [navBarUnitialState, setNavBarUnitialState] = useState(true)

  return (
    <BrowserRouter>
      <div className='logo-container' >

        <NavLink exact to='' onClick={() => setNavBarUnitialState(true)} className="nom-boutique">
          {/* {LogoSite} */}
          <svg xmlns="http://www.w3.org/2000/svg"
            width="525" height="110"
            viewBox="0 0 525 110" space="preserve"
            className="logoSvg">

            <text x="0" y="90"
              id="nametxt"
              font-size="90"
            >
              Jamais Sans mon Bijou
            </text>
          </svg>
        </NavLink>
      </div>
      <NavBar initialState={navBarUnitialState} handleChangeState={(value) => setNavBarUnitialState(value)} />
      <main className="main-container">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/missing-password" component={LoginMissing} />
          <Route exact path="/register" component={LoginCreate} />
          <Route exact path="/a-propos" component={APropos} />
          <Route exact path="/boutique" component={Boutique} />
          <Route exact path="/cgv" component={Cgv} />
          <Route exact path="/entretien" component={Entretien} />
          <Route exact path="/product-page" component={ProductPage} />
          {/* <Route path="*" component={ErrorPage} /> */}
        </Switch>
      </main>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
