import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const LoginCreate = () => {

    const loginID = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: ''
    };

    const [login, setLogin] = useState(loginID)

    const { firstName, lasttName, email, password, confirmPassword } = login

    const handelChange = (e) => {
        setLogin({ ...login, [e.target.id]: e.target.value })
    }

    const handelSubmmit = (e) => {
        e.preventDefault()
        console.log(login)
    }

    return (
        <div className='connection-page'>
            <h1>Créer un compte</h1>
            <form className='form-connection' onSubmit={handelSubmmit}>
                <label>Prénom</label>
                <input type='email' onChange={handelChange} id='email' value={firstName}></input>
                <label>Nom de famille</label>
                <input type='email' onChange={handelChange} id='email' value={lasttName}></input>
                <label>Adresse e-mail</label>
                <input type='email' onChange={handelChange} id='email' value={email}></input>
                <label>Mot de passe</label>
                <input type='password' onChange={handelChange} id='password' value={password}></input>
                <label>Confirmation mot de passe</label>
                <input type='password' onChange={handelChange} id='password' value={confirmPassword}></input>
                <div className='btn-form-connection'>
                    <button className='submit-log' type='submit'>Créer</button>
                    <NavLink exact to='login'>
                        <h4 className='cancel-create-login'>Annuler</h4>
                    </NavLink>
                </div>
            </form>
        </div>
    );
};

export default LoginCreate;