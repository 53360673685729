import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './_article.scss'

// redux dépendances
import { articleSelect } from "../../../redux/action/article.action"
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";



const Article = ({ article }) => {

    const [showDetail, setshowDetail] = useState(false)

    // Partie BackGround
    const dispatch = useDispatch(); // redux dépendances

    const dispatchArticle = () => {
        dispatch(articleSelect(article));
    }

    return (
        <>
            <div className={`article-container`}>

                <NavLink exact to='product-page'
                    className='article-pic'
                    onClick={(e) => { setshowDetail(!showDetail); dispatchArticle() }}>
                    <img className='article-img' alt='bijoux artisanal fantasie' src={article.image.url_medium} />
                </NavLink>

                <div className='article-attr'>
                    <div className='desc-like'>
                        <div className='desc-txt'>
                            <a className='article-desc'>{article.description}</a>
                            <a>...</a>
                        </div>
                        <div className='like-grp'>
                            <img className='like' alt="icon de like" src='./img/heart.svg' />
                            {article.like}
                        </div>

                    </div>
                    <div className='price-qte'>
                        <a className='article-price'>{article.prix} €</a>
                        <a className='article-qte'>Reste {article.qte} pcs</a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Article;