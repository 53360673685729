import React, { useEffect, useRef, useState } from 'react';
import './_PicsSlider.scss';

const PicsSlider = ({ listPicsUp, activePicsUrl, stateVisible }) => {

    const [selectPic, setSelectPic] = useState(0)
    const sliderImg = useRef(null)


    const fadeAnimation = (index) => {
        //let elemPics = document.querySelector(".slider-img");
        console.log(sliderImg)
        sliderImg.current.attributes.class.value = 'slider-img active';

        setTimeout(() => {
            sliderImg.current.attributes.class.value = 'slider-img';
            setSelectPic(index);
        }, 600);

    }

    const handleChangePic = (e, index) => {
        if (!e.target.className.includes('active')) {

            let elemChilds = document.querySelectorAll(".btn-pics");
            Array.from(elemChilds).map(elemChild => {
                elemChild.className = elemChild.className.replace(" active", "");
            });
            e.target.classList.toggle('active');
        };



        fadeAnimation(index);
    };



    const SvgCross = () => (
        <svg viewBox="0 0 32 32" width="32" height="32">
            <path d="M18.7692308,4 L20,5.23076923 L13.23,12 L20,18.7692308 L18.7692308,20 
                                L12,13.23 L5.23076923,20 L4,18.7692308 L10.769,12 L4,5.23076923 L5.23076923,4 
                                L12,10.769 L18.7692308,4 Z" transform="translate(4 4)" fill="white">
            </path>
        </svg>
    )

    const SvgArrow = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="28" viewBox="0 0 14 28">
            <g fill-rule="evenodd" stroke="none" stroke-width="1">
                <g fill="white" transform="translate(-1826 -526)">
                    <g transform="translate(1808 516)">
                        <path d="M37.9894251 17L24.9988982 28.9011986 12.1057782 17 11 17.9646436 24.9988982 31 39 17.9646436z" transform="rotate(-90 25 24)"></path>+
                    </g>
                </g>
            </g>
        </svg>
    )

    useEffect(() => {
        listPicsUp.map((pics, index) => {
            if (activePicsUrl === pics.medium_pic) setSelectPic(index)
        });
    }, [])

    return (
        <div className='slider-container'>
            <div className='slider-container'>
                <div className='slider-pic-container'>
                    <img className='slider-img' ref={sliderImg} src={listPicsUp[selectPic].large_pic} />
                    {(!(selectPic === 0)) &&
                        <button className='slider-btn-left' onClick={() => fadeAnimation(selectPic - 1)}>
                            <SvgArrow />
                        </button>
                    }
                    {(!(selectPic === (listPicsUp.length - 1))) &&
                        <button className='slider-btn-right' onClick={() => fadeAnimation(selectPic + 1)} >
                            <SvgArrow />
                        </button>
                    }
                    <button className="slider-btn-close" onClick={() => stateVisible(false)}>
                        <SvgCross />
                    </button>
                    <div className='btn-group-slider'>
                        {listPicsUp.map((pics, index) => (
                            <button className={`btn-pics${(index === selectPic) ? ' active' : ''}`} key={index} onClick={(e) => handleChangePic(e, index)} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PicsSlider;