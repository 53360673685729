import { FILTER_ARTICLES } from "../action/article.action";

const initialState = [];

export default function articleFilterReducer(state = initialState, action) {
    switch (action.type) {
        case FILTER_ARTICLES:
            return action.payload
        default:
            return state;
    }
}