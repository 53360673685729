import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './_APropos.scss'
require('dotenv').config();

const APropos = () => {

    const [shopStory, setShopStory] = useState();
    const serveurIp = process.env.REACT_APP_SERV_URL

    // Change le format d'encodage pour un bon affichage
    const decodeStr = (str) => {
        return str.replace(/&#(\d+);/g, function (match, dec) {
            return String.fromCharCode(dec);
        });
    };

    // Recuperation texte "story" Etsy
    const getStory = async () => {
        let response;
        try {
            response = await axios.get(serveurIp + '/getStory')
            response = response.data
            console.log(response)
            const storySplit = decodeStr(response.results[0].story).split(".")
            console.log('storySplit', storySplit)
            if (typeof response !== "undefined") {
                setShopStory({
                    story_headline: decodeStr(response.results[0].story_headline),
                    story: storySplit
                });
            }
        } catch (error) {
            console.log('requestData error:', error);
        }
    }

    useEffect(() => {
        const getData = async () => {
            await getStory();
        }
        getData();
    }, [])


    return (
        <div className='story'>
            <div className='story-container'>
                <img className='story-img' src='./img/a-propos2.jpg' />
                <div className='story-txt-container'>
                    {(shopStory) &&
                        <>
                            <h1 className='story-title'>Mon histoire</h1>
                            {shopStory.story.map((pStory) => (
                                <p className='story-txt'>{pStory + "."}</p>
                            ))}
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default APropos;